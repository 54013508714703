import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
//import Navigation from "./Components/navbar";
import Hero from "./Components/hero";
//import Footer from "./Components/footer";
//import Marketing from "./Components/marketing";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

function App() {
  return (
    <>
      <Hero />
    </>
  );
}

// function App() {
//   return (
//     <>
//       <Navigation />
//       <Hero />
//       <Marketing />
//       <Footer />
//     </>
//   );
// }
