import "../index.css";
import logo from "../media/upload_button.svg";
import downloadlogo from "../media/download.png";
import convert from "../media/convert.png";
import { useRef, useState, useEffect } from "react";

export default function Hero() {
  return (
    <div className="herocontainer">
      <img src={convert} className="convertimage" />
      <div className="heading">
        <h1>
          Convert
          <br />
          PDF to DWG
        </h1>
      </div>
      <Process />
    </div>
  );
}

//URL for uploading file
const uploadUrl = process.env.REACT_APP_UPLOAD_URL;
//URL for sending Email
const emailUrl = process.env.REACT_APP_EMAIL_URL;

function Process() {
  //state of process - 1 - file to be uploaded, 2 - file processing on server, 3 - file ready to download, enter email, 4 - Email will be sent in 60 sec
  const [state, setState] = useState(1);

  const inputRef = useRef(null); // Input object
  //const inputRef2 = useRef(null); // Input object
  const inputRef3 = useRef(null); // Input object

  const file = useRef(null); //file captured from user
  const emailcaptured = useRef(null); //email captured from user
  const responsefilename = useRef(null); //file name captured from BE

  const [fetchTracker, setfetchTracker] = useState(0); //tracker for file uploading
  const [emailfetchTracker, setemailfetchTracker] = useState(0); //tracker for email ID uploading

  useEffect(() => {
    console.log("in the effect 1");
    console.log("1: fetchtracker value: " + fetchTracker);
    if (fetchTracker == 1) {
      const uploadform = new FormData();

      uploadform.append("pdfselected", file.current);
      fetch(uploadUrl, {
        //mode: "no-cors",
        method: "POST",
        body: uploadform,
      })
        .then((response) => {
          response.json().then((parsedValue) => {
            responsefilename.current = parsedValue.file;
            console.log(responsefilename.current);
            setState(3);
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [fetchTracker]);

  useEffect(() => {
    console.log("in the effect");
    console.log("emailfetchtracker value: " + fetchTracker);
    if (emailfetchTracker == 1) {
      const emailform = {
        email: emailcaptured.current,
        id: responsefilename.current,
      };
      console.log(emailform);

      fetch(emailUrl, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(emailform),
      })
        .then((response) => {
          response.json().then((parsedValue) => {
            console.log(parsedValue);
            setState(4);
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [emailfetchTracker]);

  function onCancelClick() {
    console.log("cancel");
    file.current = null;
    emailcaptured.current = null;
    responsefilename.current = null;
    setState(1);
    setfetchTracker(0);
    setemailfetchTracker(0);
  }

  function filesubmitHandler(event) {
    console.log("in file submit handler");
    //event.preventDefault();
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    // 👇️ reset file input
    event.target.value = null;
    console.log("File Name " + fileObj.name);
    file.current = fileObj;
    setState(2);
    setfetchTracker(1);
    console.log("fetchtracker value in submitehandler: " + fetchTracker);
  }

  function emailsubmitHandler(event) {
    console.log("in email submit handler");
    emailcaptured.current = inputRef3.current.value;
    event.preventDefault();
    setemailfetchTracker(1);
    setState(4);
    console.log(
      "emailfetchtracker value in emailsubmitehandler: " + emailfetchTracker
    );
  }

  switch (state) {
    case 1:
      return (
        <div className="uploadcontainer">
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            accept=".pdf"
            name="pdfselected"
            onChange={filesubmitHandler}
          />
          <button
            type="button"
            onClick={() => {
              inputRef.current.click();
            }}
            className="uploadbutton"
          >
            <img src={logo} className="logo_button" /> UPLOAD PDF
          </button>
        </div>
      );
    case 2:
      return (
        <div className="uploadcontainer" id="uploading">
          <div className="loader"></div>
          <h3>
            Uploading file:
            <br />
            {file.current.name}
          </h3>
          <button
            type="button"
            onClick={onCancelClick}
            className="uploadbutton"
          >
            CANCEL
          </button>
        </div>
      );
    case 3:
      return (
        <div className="uploadcontainer" id="uploading">
          <div>
            <h2>Enter your Email ID</h2>

            <h6>
              <u>We will send the processed file to your Email</u>
            </h6>
          </div>
          <form className="formcontainer" onSubmit={emailsubmitHandler}>
            <input
              type="email"
              name="email"
              className="emailinput"
              ref={inputRef3}
            />
            <input type="submit" className="downloadbutton" value={"SUBMIT"} />
          </form>
          <button
            type="button"
            onClick={onCancelClick}
            className="uploadbutton"
          >
            CANCEL
          </button>
        </div>
      );
    case 4:
      return (
        <div className="uploadcontainer">
          <h1>We have sent the processed file on your Email!!</h1>
          <button
            type="button"
            onClick={() => {
              file.current = null;
              emailcaptured.current = null;
              responsefilename.current = null;
              setState(1);
              setfetchTracker(0);
              setemailfetchTracker(0);
            }}
            className="uploadbutton"
          >
            <img src={logo} className="logo_button" /> UPLOAD NEW PDF
          </button>
        </div>
      );
  }
}
